// Generated by Framer (508aa67)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/UX9_zDx7Z";

const enabledGestures = {J6W4KnQgJ: {hover: true}};

const cycleOrder = ["J6W4KnQgJ"];

const serializationHash = "framer-XQRfY"

const variantClassNames = {J6W4KnQgJ: "framer-v-1grs4zq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, bCBm81lWO: link ?? props.bCBm81lWO, MtfgFEjGh: title ?? props.MtfgFEjGh ?? "Home"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, MtfgFEjGh, bCBm81lWO, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "J6W4KnQgJ", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={bCBm81lWO} openInNewTab={false} smoothScroll><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1grs4zq", className, classNames)} framer-1gdv4t1`} data-border data-framer-name={"Nav Link"} layoutDependency={layoutDependency} layoutId={"J6W4KnQgJ"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgba(236, 236, 238, 0)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", borderBottomLeftRadius: 99, borderBottomRightRadius: 99, borderTopLeftRadius: 99, borderTopRightRadius: 99, ...style}} variants={{"J6W4KnQgJ-hover": {"--border-color": "rgb(217, 217, 217)"}}} {...addPropertyOverrides({"J6W4KnQgJ-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-7hf5jp"} data-styles-preset={"UX9_zDx7Z"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-ae4f347b-b4c7-4379-ab50-a5c3f5b08569, rgb(9, 12, 16)))"}}>Home</motion.p></React.Fragment>} className={"framer-r0bq3h"} data-framer-name={"Navigation"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"I511:3605;214:571"} style={{"--extracted-r6o4lv": "var(--token-ae4f347b-b4c7-4379-ab50-a5c3f5b08569, rgb(9, 12, 16))"}} text={MtfgFEjGh} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-XQRfY.framer-1gdv4t1, .framer-XQRfY .framer-1gdv4t1 { display: block; }", ".framer-XQRfY.framer-1grs4zq { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; overflow: visible; padding: 8px 16px 8px 16px; position: relative; text-decoration: none; width: min-content; }", ".framer-XQRfY .framer-r0bq3h { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-XQRfY.framer-1grs4zq { gap: 0px; } .framer-XQRfY.framer-1grs4zq > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-XQRfY.framer-1grs4zq > :first-child { margin-left: 0px; } .framer-XQRfY.framer-1grs4zq > :last-child { margin-right: 0px; } }", ...sharedStyle.css, ".framer-XQRfY[data-border=\"true\"]::after, .framer-XQRfY [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 71
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"K1yhnqf_F":{"layout":["auto","auto"]}}}
 * @framerVariables {"MtfgFEjGh":"title","bCBm81lWO":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerHDQkiVs57: React.ComponentType<Props> = withCSS(Component, css, "framer-XQRfY") as typeof Component;
export default FramerHDQkiVs57;

FramerHDQkiVs57.displayName = "Button/Nav Link";

FramerHDQkiVs57.defaultProps = {height: 40, width: 71};

addPropertyControls(FramerHDQkiVs57, {MtfgFEjGh: {defaultValue: "Home", displayTextArea: false, title: "Title", type: ControlType.String}, bCBm81lWO: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerHDQkiVs57, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})